<template>
  <v-row>
    <v-col col="12" :md="md">
      <v-text-field
        v-model="infoContacto.nombre"
        label="Nombre"
        type="text"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col col="12" :md="md">
      <v-text-field
        v-model="infoContacto.apellidoPaterno"
        label="Apellido paterno"
        type="text"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col 
      v-if="useMaternalName" 
      col="12" 
      :md="md">
      <v-text-field
        v-model="infoContacto.apellidoMaterno"
        label="Apellido materno"
        type="text"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col col="12" :md="md">
      <v-text-field
        v-model="infoContacto.correo"
        label="Correo"
        type="text"
        :rules="[rules.required, rules.email]"
      />
    </v-col>
  </v-row>  
</template>

<script>
import { validationMixin } from '@/mixins/formsValidations.js'
export default {
  mixins: [validationMixin],
  props: {
    infoContacto: {
      type: Object,
    },
    useMaternalName: {
      type: Boolean,
      default: false
    },
    md: {
      type: String,
      default: "12"
    }
  }
}
</script>

<style>

</style>